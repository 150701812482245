import {
  createComponent,
  Box,
  Flex,
  Heading,
  IconButton,
  Paragraph,
  SvgIcon,
} from '@byteclaw/visage';
import React from 'react';
import { Facebook, Instagram } from 'react-feather';
import NextLink from 'next/link';
import { BaseLink } from './Link';
import LogoIcon from '../assets/logo.svg';
import { InternalLink } from './InternalLink';
import {
  createCategoryHref,
  createHomepageHref,
  createStaticPageHref,
} from '../helpers/createHref';

const Nav = createComponent('nav', {
  styles: {
    px: 0,
    py: 0,
    mx: 0,
    my: 0,
  },
});

const NavUl = createComponent('ul', {
  styles: {
    listStyle: 'none',
    px: 0,
    py: 0,
    mx: 0,
    my: 0,
  },
});

interface FooterProps {
  categories: { id: string; name: string; slug: string }[];
  description: string;
  email: string;
  instagramUrl: string;
  facebookUrl: string;
  title: string;
}

export function Footer({
  categories,
  description,
  email,
  instagramUrl,
  facebookUrl,
  title,
}: FooterProps) {
  return (
    <>
      <Flex
        as="footer"
        styles={{
          backgroundColor: 'footer',
          color: 'contrast(footer 100%)',
          flexDirection: 'column',
          py: 2,
        }}
      >
        <Flex
          styles={{
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: ['100vw', 960],
            mx: 'auto',
            px: 2,
            flexWrap: 'wrap',
          }}
        >
          <Box styles={{ width: ['100%', '30%'] }}>
            <Heading level={4} styles={{ lineHeight: '1.1em' }}>
              <SvgIcon icon={LogoIcon} styles={{ mr: 2 }} />
              {title}
            </Heading>
            <Paragraph styles={{ fontSize: -1, lineHeight: -1 }}>
              {description}
            </Paragraph>
          </Box>
          <Box styles={{ ml: 'auto', mt: 4, width: ['100%', null] }}>
            <Nav>
              <NavUl>
                {categories.map((category) => (
                  <li key={category.id}>
                    <InternalLink href={createCategoryHref(category)} passHref>
                      <BaseLink>{category.name}</BaseLink>
                    </InternalLink>
                  </li>
                ))}
                <li>
                  <InternalLink
                    href={createStaticPageHref({ slug: 'o-nas' })}
                    passHref
                  >
                    <BaseLink>O nás</BaseLink>
                  </InternalLink>
                </li>
                <li>
                  <InternalLink
                    href={createStaticPageHref({ slug: 'redakcia' })}
                    passHref
                  >
                    <BaseLink>Redakcia</BaseLink>
                  </InternalLink>
                </li>
                {/* <li>
                  <InternalLink
                    href={createStaticPageHref({
                      slug: 'podmienky-ochrany-sukromia',
                    })}
                    passHref
                  >
                    <BaseLink>Podmienky ochrana súkromia</BaseLink>
                  </InternalLink>
                  </li> */}
              </NavUl>
            </Nav>
          </Box>
          <Box
            styles={{
              ml: [0, 10],
              mt: 4,
              width: ['100%', null],
            }}
          >
            <Box>
              <NextLink href={facebookUrl} passHref>
                <IconButton
                  as="a"
                  icon={Facebook}
                  label="Facebook stránka portálu Ekožurnál.sk"
                  monochromatic
                  stroked
                  styles={{ mr: 2, fontSize: 1 }}
                />
              </NextLink>
              <NextLink href={instagramUrl} passHref>
                <IconButton
                  as="a"
                  icon={Instagram}
                  label="Instagramový profil portálu Ekožurnál.sk"
                  monochromatic
                  stroked
                  styles={{ mb: 2, fontSize: 1 }}
                />
              </NextLink>
            </Box>
            <BaseLink href={`mailto://${email}`}>{email}</BaseLink>
          </Box>
        </Flex>
      </Flex>
      <Flex
        styles={{
          backgroundColor: 'footer',
          color: 'contrast(footer 100%)',
          flexDirection: 'column',
          borderTopWidth: 1,
          borderTopStyle: 'solid',
          borderTopColor: 'color(footer, shade(30%))',
          py: 2,
        }}
      >
        <Box
          styles={{
            fontSize: -1,
            lineHeight: -1,
            width: '100%',
            maxWidth: ['100vw', 960],
            mx: 'auto',
            textAlign: 'center',
          }}
        >
          © {new Date().getFullYear()}{' '}
          <InternalLink href={createHomepageHref()} passHref>
            <BaseLink styles={{ color: 'currentColor' }}>
              Brosoft s.r.o.
            </BaseLink>
          </InternalLink>
        </Box>
      </Flex>
    </>
  );
}
