import { Flex } from '@byteclaw/visage';
import React from 'react';
import Head from 'next/head';
import { Button } from './Button';
import { constructUrl } from '../utils/contructUrl';
import { siteMetadata } from '../constants';
import { InternalLink } from './InternalLink';

interface PaginatorProps {
  basePath: string;
  pageNumber: number;
  totalPages: number;
}

export function Paginator({
  basePath,
  pageNumber,
  totalPages,
}: PaginatorProps) {
  return (
    <>
      <Head>
        {totalPages > pageNumber ? (
          <link
            rel="next"
            href={constructUrl(
              siteMetadata.siteUrl,
              `${basePath}/strana/${pageNumber + 1}`,
            )}
          />
        ) : null}
        {pageNumber > 1 ? (
          <link
            rel="prev"
            href={
              pageNumber > 2
                ? constructUrl(
                    siteMetadata.siteUrl,
                    `${basePath}/strana/${pageNumber - 1}`,
                  )
                : constructUrl(siteMetadata.siteUrl, basePath)
            }
          />
        ) : null}
      </Head>
      <Flex
        styles={{
          justifyContent: 'center',
          mt: '2em',
        }}
      >
        {pageNumber > 1 ? (
          <InternalLink
            href={
              pageNumber - 1 > 1
                ? `${basePath}/strana/${pageNumber - 1}`
                : basePath
            }
            passHref
          >
            <Button
              as="a"
              styles={{
                fontSize: -1,
                lineHeight: -1,
                minWidth: [100, 200],
                mx: 1,
              }}
              rel="prev"
            >
              Novšie
            </Button>
          </InternalLink>
        ) : null}
        {totalPages > pageNumber ? (
          <InternalLink href={`${basePath}/strana/${pageNumber + 1}`} passHref>
            <Button
              as="a"
              rel="next"
              styles={{
                fontSize: -1,
                lineHeight: -1,
                minWidth: [100, 200],
                mx: 1,
              }}
            >
              Staršie
            </Button>
          </InternalLink>
        ) : null}
      </Flex>
    </>
  );
}
