import React from 'react';
import Head from 'next/head';
import { siteMetadata } from '../constants';
import { createSocialImageHomepageHref } from '../helpers/createHref';

interface SEOProps {
  description?: string;
  keywords?: string[];
  meta?: React.ReactElement[];
  image?: string;
  path: string;
  title: string;
}

export function SEO({
  description = '',
  keywords,
  meta = [],
  image = createSocialImageHomepageHref('fb'),
  title,
  path,
}: SEOProps) {
  const metaDescription = description || siteMetadata.description;

  return (
    <Head>
      <meta property="fb:app_id" content={siteMetadata.facebookAppId} />
      <meta name="description" content={metaDescription} />
      <meta property="og:description" content={metaDescription} />
      <meta name="keywords" content={keywords?.join(',')} />
      <meta property="og:title" content={title} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={siteMetadata.author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta property="og:locale" content="sk_SK" />
      <meta property="og:site_name" content={siteMetadata.title} />
      <meta property="og:image" content={image} />
      <meta
        property="og:url"
        content={`${siteMetadata.siteUrl}${path}/`.replace(/\/\/$/, '/')}
      />
      <title>
        {title} | {siteMetadata.title}
      </title>
      {meta}
    </Head>
  );
}
