import React from 'react';
import { IReadTimeResults } from 'reading-time';
import { Article as JSONLDArticle, WithContext } from 'schema-dts';
import { siteMetadata } from '../constants';
import {
  createArticleHref,
  createSocialImageArticleHref,
} from '../helpers/createHref';
import { ArticleCardFragment } from '../fragments.graphql';
import { constructUrl } from '../utils/contructUrl';
import { JsonLd } from './JsonLd';

interface ArticleCardJSONLDProps {
  article: ArticleCardFragment & { timeToRead: IReadTimeResults };
}

export function ArticleCardJSONLD({ article }: ArticleCardJSONLDProps) {
  const data: WithContext<JSONLDArticle> = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    url: constructUrl(siteMetadata.siteUrl, createArticleHref(article)),
    articleSection: article.categories.map((c) => c.name).join(', '),
    author: {
      '@type': 'Person',
      name: article.author?.name,
    },
    headline: article.title,
    // @todo compute image urls
    image: [
      createSocialImageArticleHref('gl-1x1', {
        photoUrl: article.titlePicture.url,
        publishedAt: article.updatedAt,
      }),
      createSocialImageArticleHref('gl-4x3', {
        photoUrl: article.titlePicture.url,
        publishedAt: article.updatedAt,
      }),
      createSocialImageArticleHref('gl-16x9', {
        photoUrl: article.titlePicture.url,
        publishedAt: article.updatedAt,
      }),
    ],
    publisher: {
      '@type': 'Organization',
      name: 'Ekožurnál.sk',
      logo: {
        '@type': 'ImageObject',
        url: 'https://ekozurnal.sk/publisher_logo.png',
      },
    },
    wordCount: article.timeToRead.words,
    dateCreated: article.createdAt,
    datePublished: article.publishedAt ?? undefined,
    dateModified: article.updatedAt,
    description: article.excerpt || '',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': constructUrl(siteMetadata.siteUrl, createArticleHref(article)),
    },
  };

  return <JsonLd<JSONLDArticle> item={data} />;
}
