import { createComponent } from '@byteclaw/visage';
import React from 'react';
import { FormattedDate } from 'react-intl';

const Time = createComponent('time', {
  displayName: 'ArticlePublishDate',
  styles: {},
});

interface ArticlePublishDateProps {
  value: string;
}

export const ArticlePublishDate = ({ value }: ArticlePublishDateProps) => {
  return (
    <Time dateTime={value}>
      <FormattedDate day="numeric" month="long" value={value} year="numeric" />
    </Time>
  );
};
