import { Box, Flex } from '@byteclaw/visage';
import React, { ReactNode } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { siteMetadata } from '../constants';

interface LayoutProps {
  children: ReactNode;
  categories: { id: string; name: string; slug: string }[];
  maxWidth?: number;
}

export const Layout = ({
  categories,
  children,
  maxWidth = 960,
}: LayoutProps) => {
  return (
    <Flex styles={{ minHeight: '100vh', flexDirection: 'column' }}>
      <Header
        categories={categories}
        facebookUrl={siteMetadata.facebookUrl}
        instagramUrl={siteMetadata.instagramUrl}
        siteTitle={siteMetadata.title}
      />
      <Box
        as="main"
        styles={{
          display: 'block',
          mx: 'auto',
          my: 4,
          maxWidth: ['100vw', maxWidth],
          width: '100%',
          flexGrow: 1,
          pt: 0,
          pb: 2,
          px: 2,
        }}
      >
        {children}
      </Box>
      <Footer categories={categories} {...siteMetadata} />
    </Flex>
  );
};
