import {
  Box,
  createComponent,
  Flex,
  Heading,
  Paragraph,
  Text,
} from '@byteclaw/visage';
import React from 'react';
import Image from 'next/image';
import { IReadTimeResults } from 'reading-time';
import { ArticlePublishDate } from './ArticlePublishDate';
import { ArticleCardJSONLD } from '../jsonld/ArticleCardJSONLD';
import { Link } from './Link';
import { TimeToRead } from './TimeToRead';
import { createArticleHref } from '../helpers/createHref';
import { ArticleCardFragment } from '../fragments.graphql';
import { generateImageSizes } from '../helpers/generateImageSizes';

export type ArticleCardData = ArticleCardFragment & {
  timeToRead: IReadTimeResults;
};

const ArticleCardBox = createComponent(Link, {
  displayName: 'ArticleCardBox',
  styles: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    borderBottomWidth: 0,
    gridColumn: 'span 1',
    gridRow: 'span 1',
    '&:hover': {
      color: 'currentColor',
    },
  },
});

const DividerSquare = createComponent(Box, {
  displayName: 'DividerSquare',
  styles: {
    backgroundColor: 'currentColor',
    display: 'inline-block',
    mx: 1,
    height: 6,
    width: 6,
    verticalAlign: 'middle',
  },
});

interface ArticleCardProps {
  article: ArticleCardData;
  compact?: boolean;
}

export function ArticleCard({ article, compact }: ArticleCardProps) {
  const { author } = article;

  return (
    <ArticleCardBox href={createArticleHref(article)}>
      <Box styles={{ position: 'relative', height: 170 }}>
        <Image
          src={article.titlePicture.url}
          objectFit="cover"
          layout="fill"
          sizes={generateImageSizes(370)}
        />
      </Box>
      <Flex styles={{ flexDirection: 'column' }}>
        <ArticleCardJSONLD article={article} />
        <Heading
          level={4}
          styles={{ fontSize: compact ? 0 : 1, lineHeight: '1.2em', mb: 1 }}
        >
          {article.title}
        </Heading>
        {compact ? null : (
          <>
            <Paragraph
              styles={{
                color: 'color(shadesText tint(30%))',
                fontSize: -1,
                lineHeight: '1.2em',
                my: 2,
              }}
            >
              {article.excerpt || ''}
            </Paragraph>
          </>
        )}
        <Text styles={{ fontSize: -2, lineHeight: '1.5em' }}>
          {author?.name} v téme{' '}
          {article.categories[0] ? article.categories[0].name : null}
        </Text>
        <Text
          styles={{
            color: 'color(shadesText tint(30%))',
            fontSize: -2,
            lineHeight: '1.1em',
          }}
        >
          <ArticlePublishDate
            // @ts-expect-error publishedAt is always set because we require only published articles
            value={article.publishedAt}
          />
          <DividerSquare />
          <TimeToRead minutes={article.timeToRead.minutes} />
        </Text>
      </Flex>
    </ArticleCardBox>
  );
}
