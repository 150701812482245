import { useRouter } from 'next/router';
import Link, { LinkProps } from 'next/link';
import React, { Children } from 'react';

export const InternalLink: React.FC<LinkProps> = ({ children, ...props }) => {
  const { asPath } = useRouter();
  const child = Children.only(children);

  // pages/index.js will be matched via props.href
  // pages/about.js will be matched via props.href
  // pages/[slug].js will be matched via props.as
  const isActive = asPath === props.href || asPath === props.as;

  return (
    <Link {...props}>
      {React.cloneElement(child as any, {
        'data-is-active': isActive,
      })}
    </Link>
  );
};
