import { Box, createComponent, Flex, Heading, Text } from '@byteclaw/visage';
import { MDXRemote, MDXRemoteSerializeResult } from 'next-mdx-remote';
import React, { createContext, useContext } from 'react';
import Image from 'next/image';
import { Button } from './Button';
import { Link } from './Link';
import { createAuthorHref } from '../helpers/createHref';
import { generateImageSizes } from '../helpers/generateImageSizes';

export type AuthorsListAuthorData = {
  bioMdx: MDXRemoteSerializeResult;
  id: string;
  name: string;
  gender: 'male' | 'female';
  photo: {
    url: string;
  };
  position?: string | null;
  slug: string;
};

export const AuthorsListContext = createContext<AuthorsListAuthorData[]>([]);

const List = createComponent('div', {
  defaultProps: {
    role: 'list',
  },
  styles: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
});

const ListItem = createComponent('div', {
  defaultProps: {
    role: 'listitem',
  },
  styles: {
    mb: 5,
    width: '100%',
  },
});

interface AuthorRowCardProps {
  author: {
    bioMdx: MDXRemoteSerializeResult;
    id: string;
    name: string;
    gender: 'male' | 'female';
    photo: {
      url: string;
    };
    position?: string | null;
    slug: string;
  };
}

export function AuthorListItem({ author }: AuthorRowCardProps) {
  return (
    <ListItem>
      <Flex
        styles={{
          alignItems: ['center', null],
          flexDirection: ['column', 'row'],
        }}
      >
        <Box
          styles={{
            borderRadius: '50%',
            overflow: 'hidden',
            width: 100,
            height: 100,
            position: 'relative',
          }}
        >
          <Image
            src={author.photo.url}
            layout="fill"
            objectFit="cover"
            sizes={generateImageSizes(100)}
          />
        </Box>
        <Box styles={{ ml: [0, 4], maxWidth: 500 }}>
          <Heading
            styles={{
              fontSize: 5,
              lineHeight: '1.1em',
              textAlign: ['center', 'left'],
            }}
          >
            {author.name}
          </Heading>
          {author.position ? (
            <Text
              styles={{
                display: 'block',
                color: 'color(shadesText, tint(30%))',
                fontSize: -1,
                lineHeight: -1,
                mb: 2,
                width: '100%',
                textAlign: ['center', 'left'],
              }}
            >
              {author.position}
            </Text>
          ) : null}
          <Box styles={{ fontSize: -1, lineHeight: '1.3em' }}>
            <MDXRemote {...author.bioMdx} />
          </Box>
          <Flex styles={{ width: '100%' }}>
            <Button
              as={Link}
              href={createAuthorHref(author)}
              styles={{ ml: 'auto', mr: ['auto', null] }}
            >
              Prejsť na články {author.gender === 'male' ? 'autora' : 'autorky'}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </ListItem>
  );
}

interface AuthorsListProps {
  ids: string[];
}

export function AuthorsList({ ids }: AuthorsListProps) {
  const authors = useContext(AuthorsListContext);

  return (
    <List>
      {ids.map((id) => {
        const author = authors.find((a) => a.id === id);

        if (!author) {
          return <React.Fragment key={id} />;
        }

        return <AuthorListItem key={id} author={author} />;
      })}
    </List>
  );
}
