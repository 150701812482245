import { createComponent } from '@byteclaw/visage';

export const ArticleGrid = createComponent('div', {
  displayName: 'ArticleGrid',
  styles: {
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: [
      'repeat(auto-fill, minmax(200px, auto))',
      'repeat(3, minmax(0, 1fr))',
    ],
    gridColumnGap: '1rem',
    gridRowGap: '2rem',
    gridTemplateRows: 'auto',
    my: 4,
  },
});
