import { Box, Grid, Heading } from '@byteclaw/visage';
import React from 'react';
import NextLink from 'next/link';
import Image from 'next/image';
import { BaseLink } from './Link';
import { ArticleCardJSONLD } from '../jsonld/ArticleCardJSONLD';
import { createArticleHref } from '../helpers/createHref';
import { ArticleCardData } from './ArticleCard';
import { generateImageSizes } from '../helpers/generateImageSizes';

interface FeaturedArticleCard {
  article: ArticleCardData;
  variant?: 'big' | 'small';
}

function FeaturedArticleCard({
  article,
  variant = 'big',
}: FeaturedArticleCard) {
  return (
    <NextLink href={createArticleHref(article)} passHref>
      <BaseLink
        styles={{
          color: 'shadesText',
          display: 'block',
          gridColumn: variant === 'big' ? 1 : [1, 2],
          gridRow: variant === 'big' ? ['1 / span 1', '1 / span 2'] : 'span 1',
          position: 'relative',
          width: '100%',
          '&:hover': {
            textDecoration: 'none',
            '& > [data-title-image="true"]': {
              backgroundImage:
                'linear-gradient(to bottom, transparent 0, rgba(0,0,0,.5) 100%)',
            },
          },
        }}
      >
        <ArticleCardJSONLD article={article} />
        <Image
          src={article.titlePicture.url}
          layout="fill"
          objectFit="cover"
          sizes={generateImageSizes(470)}
        />
        <Box
          data-title-image="true"
          styles={{
            backgroundImage: `linear-gradient(to bottom, transparent 0, rgba(0,0,0,.75) 100%)`,
            display: 'flex',
            position: 'absolute',
            alignItems: 'flex-end',
            justifyContent: 'center',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden',
          }}
        >
          <Box
            styles={{ backgroundColor: 'shades', maxWidth: '80%', mb: 2, p: 1 }}
          >
            <Heading
              level={4}
              styles={{
                px: 0,
                py: 0,
                mx: 0,
                my: 0,
                fontSize: variant === 'big' ? 2 : 1,
                lineHeight: '1.1em',
                textAlign: 'center',
              }}
            >
              {article.title}
            </Heading>
          </Box>
        </Box>
      </BaseLink>
    </NextLink>
  );
}

interface NewestArticlesProps {
  articles: ArticleCardData[];
}

export const NewestArticles = ({ articles }: NewestArticlesProps) => {
  return (
    <Grid
      styles={{
        gridColumnGap: '1rem',
        gridRowGap: '1rem',
        gridTemplateColumns: ['1fr', '50% 1fr'],
        gridTemplateRows: ['300px 200px 200px', '240px 240px'],
      }}
    >
      {articles.map((article, i) => (
        <FeaturedArticleCard
          article={article}
          key={article.id}
          variant={i === 0 ? 'big' : 'small'}
        />
      ))}
    </Grid>
  );
};
