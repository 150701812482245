import {
  createComponent,
  Link as VisageLink,
  ExtractVisageComponentProps,
} from '@byteclaw/visage';
import NextLink from 'next/link';
import React from 'react';

export const BaseLink = createComponent(VisageLink, {
  styles: {
    color: 'currentColor',
    '&:hover': {
      textDecoration: 'none',
    },
  },
});

type VisageLinkProps = ExtractVisageComponentProps<typeof VisageLink>;

export const Link = ({
  children,
  href = '',
  rel,
  ...restProps
}: VisageLinkProps) => {
  if (href?.startsWith('http') || href?.startsWith('mailto')) {
    return (
      <BaseLink
        href={href}
        rel={`noopener noreferrer nofollow ${rel || ''}`.trim()}
        target="_blank"
        {...restProps}
      >
        {children}
      </BaseLink>
    );
  }

  return (
    <NextLink href={href} passHref>
      <BaseLink rel={`noopener ${rel || ''}`.trim()} {...restProps}>
        {children}
      </BaseLink>
    </NextLink>
  );
};
