import {
  Box,
  createComponent,
  Drawer,
  Flex,
  IconButton,
  IsBreakpoint,
  List,
  ListItem,
  ListItemLink,
  DrawerPosition,
  SvgIcon,
} from '@byteclaw/visage';
import Link from 'next/link';
import React, { useState } from 'react';
import { Facebook, Instagram, Menu, X } from 'react-feather';
import LogoIcon from '../assets/logo.svg';
import { createCategoryHref, createHomepageHref } from '../helpers/createHref';
import { InternalLink } from './InternalLink';

const LogoLink = createComponent('a', {
  displayName: 'LogoLink',
  styles: {
    alignItems: 'center',
    borderBottomWidth: 0,
    color: 'shadesText',
    display: 'inline-flex',
    fontFamily: 'heading',
    fontSize: 1,
    lineHeight: 1,
    fontWeight: 'bold',
    p: 2,
    '&:hover': {
      color: 'primaryText',
      backgroundColor: 'color(primary tint(20%))',
      textDecoration: 'none',
    },
    '& span[data-title]': {
      position: ['absolute', null],
      overflow: ['hidden', null],
      clip: ['rect(0 0 0 0 )', null],
      height: [1, null],
      width: [1, null],
      m: ['-1px', null],
      p: [0, null],
      border: [0, null],
    },
  },
});

const NavigationLink = createComponent('a', {
  displayName: 'NavigationLink',
  styles: {
    borderBottomWidth: 0,
    color: 'shadesText',
    display: 'inline-flex',
    fontFamily: 'heading',
    fontSize: 1,
    lineHeight: 1,
    fontWeight: 'normal',
    p: 2,
    '&[data-is-active="true"]': {
      color: 'primary',
    },
    '&:hover': {
      backgroundColor: 'color(primary tint(80%))',
      color: 'shadesText',
      textDecoration: 'none',
    },
  },
});

interface HeaderProps {
  categories: { id: string; name: string; slug: string }[];
  facebookUrl: string;
  instagramUrl: string;
  siteTitle?: string;
}

export const Header = ({
  categories,
  facebookUrl,
  instagramUrl,
  siteTitle = '',
}: HeaderProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Box
      as="header"
      styles={{
        alignItems: 'center',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 12px 0px',
        color: 'shadesText',
        px: 0,
        mb: 2,
        width: '100%',
      }}
    >
      <Flex
        styles={{
          alignItems: 'center',
          mx: 'auto',
          maxWidth: ['100vw', 960],
          pr: 2,
        }}
      >
        <Link href={createHomepageHref()} passHref>
          <LogoLink>
            <SvgIcon
              icon={LogoIcon}
              styles={{ fontSize: '1.5em', lineHeight: 1, mr: 2 }}
            />{' '}
            <span data-title>{siteTitle}</span>
          </LogoLink>
        </Link>
        <Box as="nav" styles={{ display: ['none', 'block'] }}>
          {categories.map((category) => {
            return (
              <InternalLink
                key={category.id}
                href={createCategoryHref(category)}
                passHref
              >
                <NavigationLink>{category.name}</NavigationLink>
              </InternalLink>
            );
          })}
        </Box>
        <Link href={facebookUrl} passHref>
          <IconButton
            as="a"
            label="Facebook stránka portálu Ekožurnál.sk"
            title="Facebook stránka portálu Ekožurnál.sk"
            icon={Facebook}
            styles={{ ml: 'auto', mr: 2 }}
            stroked
          />
        </Link>
        <Link href={instagramUrl} passHref>
          <IconButton
            as="a"
            label="Instagramový profil portálu Ekožurnál.sk"
            icon={Instagram}
            styles={{ mr: 2 }}
            stroked
            title="Instagramový profil portálu Ekožurnál.sk"
          />
        </Link>
        <IsBreakpoint is={0}>
          <IconButton
            label="Otvoriť navigáciu"
            icon={Menu}
            onClick={() => setDrawerOpen(true)}
            monochromatic
            stroked
            type="button"
          />
          <Drawer
            styles={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
            side={DrawerPosition.right}
            onClose={() => setDrawerOpen(false)}
            open={drawerOpen}
            inPortal
          >
            <IconButton
              label="Zavrieť navigáciu"
              icon={X}
              onClick={() => setDrawerOpen(false)}
              styles={{ fontSize: 2, lineHeight: 2, ml: 'auto', p: 2 }}
              type="button"
            />
            <List>
              {categories.map((category) => (
                <ListItem key={category.id}>
                  <Link href={createCategoryHref(category)} passHref>
                    <ListItemLink as={NavigationLink}>
                      {category.name}
                    </ListItemLink>
                  </Link>
                </ListItem>
              ))}
            </List>
          </Drawer>
        </IsBreakpoint>
      </Flex>
    </Box>
  );
};
