export const createCategoryHref = (category: { slug: string }) => {
  return `/${category.slug}`;
};

export const createHomepageHref = () => '/';

export const createArticleHref = (article: {
  slug: string;
  createdAt: string;
}) => {
  return `/${Math.floor(new Date(article.createdAt).getTime() / 1000)}/${
    article.slug
  }`;
};

export const createAuthorHref = (author: { slug: string }) => {
  return `/redaktor/${author.slug}`;
};

export const createStaticPageHref = (staticPage: { slug: string }) => {
  return `/s/${staticPage.slug}`;
};

export const createSocialImageHomepageHref = (
  template: 'fb' | 'gl-1x1' | 'gl-4x3' | 'gl-16x9',
): string => {
  return `https://og.ekozurnal.sk/homepage?s=${template}`;
};

export const createSocialImageStaticPageHref = (
  template: 'fb' | 'gl-1x1' | 'gl-4x3' | 'gl-16x9',
): string => {
  return `https://og.ekozurnal.sk/homepage?s=${template}`;
};

export const createSocialImageAuthorHref = (
  template: 'fb' | 'gl-1x1' | 'gl-4x3' | 'gl-16x9',
  {
    name,
    photoUrl,
    publishedAt,
  }: {
    name: string;
    publishedAt?: string | null;
    photoUrl: string;
  },
): string => {
  const url = new URL('/author', 'https://og.ekozurnal.sk');

  url.searchParams.append('s', template);
  url.searchParams.append('name', name);
  url.searchParams.append('pic', photoUrl);

  if (publishedAt) {
    url.searchParams.append('v', publishedAt);
  }

  return url.toString();
};

export const createSocialImageArticleHref = (
  template: 'fb' | 'gl-1x1' | 'gl-4x3' | 'gl-16x9',
  {
    photoUrl,
    publishedAt,
  }: {
    publishedAt?: string | null;
    photoUrl: string;
  },
): string => {
  const url = new URL('/article', 'https://og.ekozurnal.sk');

  url.searchParams.append('s', template);
  url.searchParams.append('pic', photoUrl);

  if (publishedAt) {
    url.searchParams.append('v', publishedAt);
  }

  return url.toString();
};

export const createSocialImageCategoryHref = (
  template: 'fb' | 'gl-1x1' | 'gl-4x3' | 'gl-16x9',
  {
    name,
    publishedAt,
  }: {
    name: string;
    publishedAt?: string | null;
  },
): string => {
  const url = new URL('/category', 'https://og.ekozurnal.sk');

  url.searchParams.append('s', template);
  url.searchParams.append('name', name);

  if (publishedAt) {
    url.searchParams.append('v', publishedAt);
  }

  return url.toString();
};
