import { createComponent, variant, variantStyles } from '@byteclaw/visage';

export const Button = createComponent('button', {
  displayName: 'Button',
  styles: {
    appearance: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px 0px',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: 0,
    lineHeight: 0,
    mx: 0,
    my: 0,
    px: 2,
    py: 1,
    ...variantStyles('variant', {
      default: {
        backgroundColor: 'shades',
        color: 'shadesText',
        '&:focus': {
          boxShadow:
            'rgba(0, 0, 0, 0.15) 0px 4px 12px 0px, inset 0 0 2px rgba(0, 0, 0, 0.2)',
          zIndex: 1,
        },
        '&:focus:active': {
          boxShadow:
            'rgba(0, 0, 0, 0.15) 0px 4px 12px 0px,inset 0 0 2px rgba(0, 0, 0, 0.2)',
          zIndex: 1,
        },
        '&:hover': {
          boxShadow:
            'rgba(0, 0, 0, 0.15) 0px 4px 12px 0px, inset 0 0 2px rgba(0, 0, 0, 0.2)',
        },
      },
      primary: {
        backgroundColor: 'primary',
        color: 'primaryText',
        '&:focus': {
          boxShadow:
            'rgba(0, 0, 0, 0.15) 0px 4px 12px 0px, inset 0 0 2px rgba(0, 0, 0, 0.2)',
          zIndex: 1,
        },
        '&:focus:active': {
          backgroundColor: 'color(primary shade(10%))',
          boxShadow:
            'rgba(0, 0, 0, 0.15) 0px 4px 12px 0px,inset 0 0 2px rgba(0, 0, 0, 0.2)',
          zIndex: 1,
        },
        '&:hover': {
          backgroundColor: 'color(primary tint(10%))',
          boxShadow:
            'rgba(0, 0, 0, 0.15) 0px 4px 12px 0px, inset 0 0 2px rgba(0, 0, 0, 0.2)',
        },
      },
    }),
  },
  variants: [variant('variant', true, ['primary'] as const)],
});
