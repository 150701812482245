import { FormattedPlural } from 'react-intl';
import React from 'react';

interface TimeToReadProps {
  long?: boolean;
  minutes: number;
}

export function TimeToRead({ long = false, minutes }: TimeToReadProps) {
  return (
    <FormattedPlural
      zero=""
      one={long ? '1 minúta čítania' : '1 minúta'}
      two={long ? '2 minúty čítania' : '2 minúty'}
      few={long ? `${minutes} minúty čítania` : `${minutes} minúty`}
      other={long ? `${minutes} minút čítania` : `${minutes} minút`}
      value={minutes}
    />
  );
}
